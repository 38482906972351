import React from "react";
import { css } from "@emotion/react";
import Img from "gatsby-image";
import {
    centeredBlock,
    mediaMaxPageWidth,
    mediaMobile,
    ctaButtonStyle,
    mediaSmall,
    mediaMedium,
} from "../style/shared";
import { fontSize42, fontSize28 } from "../style/fonts";
import MailLink from "./MailLink";
import { toTransparent, analyticsEvent } from "../utils";
import { Link } from "gatsby";
import { halfMaxContentWidth } from "../style/constants";

const bannerStyle = (color) => css`
    background-color: ${color};
    overflow: hidden;
    height: 420px;
    position: relative;
    ${centeredBlock};

    ${mediaMobile} {
        height: 320px;
    }
`;
const imageWrapperStyle = (offset) => css`
    height: 100%;
    position: absolute;
    left: calc(50% - ${halfMaxContentWidth}px + ${offset}px);
    bottom: 0;
    top: 0;
    right: calc(50% - ${halfMaxContentWidth}px - ${offset}px);

    ${mediaMobile} {
        right: calc(- ${offset}px - 40%);
        left: calc(${offset}px - 40%);
    }
`;

const imageStyle = css`
    height: 100% !important;
    margin-left: auto;
    margin-right: auto;
`;

const bannerContentStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    height: 100%;

    padding-left: 50%;

    ${mediaMaxPageWidth} {
        padding-left: 45%;
    }

    ${mediaSmall} {
        padding-left: 35%;
    }

    ${mediaMobile} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const sloganStyle = css`
    margin: auto 0;
    width: 100%;
    color: white;

    ${fontSize42};
    text-shadow: 0 0 500px rgba(0, 0, 0, 0.7);

    ${mediaMobile} {
        ${fontSize28};
        text-shadow: 0 0 400px rgba(0, 0, 0, 0.8);
        text-align: center;
    }
`;

const ctaStyle = css`
    ${ctaButtonStyle};
    margin: -64px auto 64px;

    ${mediaSmall} {
        margin-bottom: 48px;
    }
`;

const overlayStyle = (color, angle = 90) => {
    const transparent = toTransparent(color);
    return css`
        position: absolute;
        top: 0;
        left: -25%;
        right: -25%;
        bottom: 0;
        z-index: -1;

        background: linear-gradient(
            ${angle}deg,
            ${color} 0,
            ${color} 25.3%,
            ${transparent} 38.6%,
            ${transparent} 46%,
            ${color} 59.2%,
            ${color} 100%
        );

        ${mediaMedium} {
            background: linear-gradient(
                ${angle}deg,
                ${color} 0,
                ${color} 20%,
                ${transparent} 30%,
                ${transparent} 39%,
                ${color} 61%,
                ${color} 100%
            );
        }

        ${mediaSmall} {
            background: linear-gradient(
                ${angle}deg,
                ${transparent} 39%,
                ${color} 61%,
                ${color} 100%
            );
        }

        ${mediaMobile} {
            background: radial-gradient(
                ${toTransparent(color, 0.3)} 40%,
                ${toTransparent(color, 0.9)}
            );
        }
    `;
};

const renderCta = (ctaType, slogan) => {
    if (ctaType === "mail") {
        return (
            <MailLink
                css={ctaStyle}
                to="info"
                onClick={() => analyticsEvent("cta", "click", slogan)}
            >
                Schreiben Sie uns
            </MailLink>
        );
    }
    if (ctaType === "visit" || ctaType === "tel") {
        return (
            <Link
                css={ctaStyle}
                to="/#contact"
                onClick={() => analyticsEvent("cta", "click", slogan)}
            >
                {ctaType === "visit" ? "Kommen Sie vorbei" : "Rufen Sie uns an"}
            </Link>
        );
    }
};

export default ({ color, image, offset, slogan, angle, ctaType, alt }) => (
    <div css={bannerStyle(color)}>
        <div css={imageWrapperStyle(offset)}>
            <Img css={imageStyle} fixed={image} alt={alt} />
        </div>
        <div css={bannerContentStyle}>
            <div css={overlayStyle(color, angle)} />
            <p css={sloganStyle}>{slogan}</p>
            {renderCta(ctaType, slogan)}
        </div>
    </div>
);
