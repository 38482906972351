import React from "react";
import { analyticsEvent } from "../utils";

export default class MailLink extends React.Component {
    state = { mail: null };

    componentDidMount() {
        this.setState({ mail: `${this.props.to}@steuerschmitt.com` });
    }

    onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
        analyticsEvent("mail", "click", `${this.props.to}@steuerschmitt.com`);
    };

    render() {
        if (!this.state.mail) {
            return null;
        }
        return (
            <a
                className={this.props.className}
                href={`mailto:${this.state.mail}`}
                onClick={this.onClick}
            >
                {this.props.children || this.state.mail}
            </a>
        );
    }
}
