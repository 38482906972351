export const parseQuery = (location) => {
    if (!location.search) {
        return {};
    }
    const query = {};
    const queryVars = location.search.substring(1).split("&");
    queryVars.forEach((queryVar) => {
        const [key, value] = queryVar.split("=");
        query[decodeURIComponent(key)] = decodeURIComponent(value);
    });
    return query;
};

export const analyticsEvent = (category, action, label) => {
    if (process.env.NODE_ENV === "production") {
        if (typeof ga === "function") {
            label
                ? window.ga("send", "event", category, action, label)
                : window.ga("send", "event", category, action);
        }
    } else {
        console.debug("[Analytics] would send event ", {
            category,
            action,
            label,
        });
    }
};

export const toTransparent = (colorString, alpha = 0) => {
    const r = parseInt(colorString.slice(1, 3), 16);
    const g = parseInt(colorString.slice(3, 5), 16);
    const b = parseInt(colorString.slice(5, 7), 16);

    return `rgba(${r},${g},${b},${alpha})`;
};

export const isMobile = () => {
    return (
        !!window.matchMedia && window.matchMedia("(max-width: 540px)").matches
    );
};
